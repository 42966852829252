<template>
  <div>
    <b-modal
      id="modal-login"
      no-close-on-backdrop
      centered
      ok-only
      size="lg"
      hide-footer="true"
      :title="sidebarTitle"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              ref="image"
              label="Image"
              invalid-feedback="Image is required."
            >
              <b-media no-body>
                <div v-if="logoloading == true">
                  <b-spinner
                    variant="primary"
                    type="grow"
                    class="m-5"
                    label="Spinning"
                  ></b-spinner>
                </div>

                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      rounded
                      src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                      @click="$refs.fileProfile.click()"
                      v-if="logoloading == false"
                      style="height: 150px; width: 150px; object-fit: cover"
                    />
                  </b-link>

                  <b-link v-if="logoloading == 'loaded'">
                    <div>
                      <i
                        @click="deleteLogo()"
                        style="margin-top: 70px; margin-left: 70px"
                        class="fa fa-trash fa-md text-danger position-absolute"
                      ></i>
                    </div>
                    <b-img
                      rounded
                      :src="plus"
                      style="height: 150px; width: 150px; object-fit: cover"
                    />
                    <br />
                  </b-link>

                  <input
                    type="file"
                    id="fileProfile"
                    hidden
                    ref="fileProfile"
                    @change="selectlogo()"
                    accept="image/*"
                  />
                </b-media-aside>
              </b-media>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="salesforceID">
              <h5>{{ myObj.saleforceID }}</h5>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Title"
              invalid-feedback="Title is required."
              ref="title"
            >
              <b-form-input
                v-model.trim="myObj.title"
                placeholder="Enter title here"
                @focusout="checkTitle()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Button Title"
              invalid-feedback="Button Title is required."
              ref="button"
            >
              <b-form-input
                v-model.trim="myObj.buttonTitle"
                placeholder="Enter button title"
                @focusout="checkButton()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Redirect To"
              invalid-feedback="Redirect To is required."
            >
              <v-select
                v-model="myObj.actionType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="actionOptions"
                :reduce="(val) => val.value"
                label="text"
                :clearable="false"
                placeholder="Select"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="myObj.actionType == 'page'">
            <b-form-group
              label="Page"
              invalid-feedback="Page is required."
              ref="page"
            >
              <v-select
                v-model="myObj.pageID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                label="title"
                :reduce="(val) => val.id"
                :clearable="false"
                placeholder="select page"
                @input="checkPage()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-else-if="myObj.actionType == 'url'">
            <b-form-group
              label="URL"
              invalid-feedback="URL is required."
              ref="url"
            >
              <b-form-input
                v-model.trim="myObj.url"
                placeholder="Enter url"
                @focusout="CheckUrl()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-else-if="myObj.actionType == 'payment'">
            <b-form-group
              label="Amount"
              invalid-feedback="Amount is required."
              ref="amount"
            >
              <b-form-input
                v-model="myObj.amount"
                placeholder="Enter amount"
                type="number"
                @focusout="checkAmount()"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Category"
              invalid-feedback="Category is required."
              ref="cat"
            >
              <v-select
                v-model="myObj.category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categories"
                :clearable="false"
                placeholder="select category"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Country"
              invalid-feedback="Country is required."
              ref="country"
            >
              <v-select
                v-model="myObj.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countries"
                :clearable="false"
                placeholder="select country"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group
          label="Details"
          invalid-feedback="Details is required."
          ref="details"
        >
          <b-form-textarea
            v-model.trim="myObj.details"
            placeholder="Enter details here"
          />
          <!--  @focusout="checkDetails()"  -->
        </b-form-group>
      </b-form>
      <br />
      <div>
        <b-button
          class="float-right ml-5"
          variant="primary"
          small
          :disabled="logoloading == true || request"
          @click="AddFlavour()"
        >
          <b-spinner
            v-if="request"
            variant="light"
            small
            type="grow"
            label="Spinning"
          ></b-spinner>
          <span class="text-nowrap" v-else>Save</span>
        </b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-section"
      no-close-on-backdrop
      centered
      ok-only
      size="lg"
      hide-footer="true"
      :title="sidebarTitle"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              ref="subimage"
              label="Image"
              invalid-feedback="Image is required."
            >
              <b-media no-body>
                <div v-if="imgLoading == true">
                  <b-spinner
                    variant="primary"
                    type="grow"
                    class="m-5"
                    label="Spinning"
                  ></b-spinner>
                </div>

                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      rounded
                      src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                      @click="$refs.subProfile.click()"
                      v-if="imgLoading == false"
                      style="height: 150px; width: 150px; object-fit: cover"
                    />
                  </b-link>

                  <b-link v-if="imgLoading == 'loaded'">
                    <div>
                      <i
                        @click="deleteSecImg()"
                        style="margin-top: 70px; margin-left: 70px"
                        class="fa fa-trash fa-md text-danger position-absolute"
                      ></i>
                    </div>
                    <b-img
                      rounded
                      :src="secObj.image"
                      style="height: 150px; width: 150px; object-fit: cover"
                    />
                    <br />
                  </b-link>

                  <input
                    type="file"
                    id="subProfile"
                    hidden
                    ref="subProfile"
                    @change="selectSecImg()"
                    accept="image/*"
                  />
                </b-media-aside>
              </b-media>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="salesforceID">
              <h5>{{ secObj.saleforceID }}</h5>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Title"
              invalid-feedback="Title is required."
              ref="subtitle"
            >
              <b-form-input
                v-model.trim="secObj.title"
                placeholder="Enter title here"
                @focusout="CheckSubTitle()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Button Title"
              invalid-feedback="Button Title is required."
              ref="subbutton"
            >
              <b-form-input
                v-model.trim="secObj.buttonTitle"
                placeholder="Enter button title"
                @focusout="CheckSubButton()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Redirect To"
              invalid-feedback="Redirect To is required."
            >
              <v-select
                v-model="secObj.actionType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="subactionOptions"
                :reduce="(val) => val.value"
                label="text"
                :clearable="false"
                placeholder="Select"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="secObj.actionType == 'page'">
            <b-form-group
              label="Page"
              invalid-feedback="Page is required."
              ref="subpage"
            >
              <v-select
                v-model="secObj.pageID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                label="title"
                :reduce="(val) => val.id"
                :clearable="false"
                placeholder="select page"
                @input="checkSubPage()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-else-if="secObj.actionType == 'url'">
            <b-form-group
              label="URL"
              invalid-feedback="URL is required."
              ref="suburl"
            >
              <b-form-input
                v-model.trim="secObj.url"
                placeholder="Enter url"
                @focusout="CheckSubUrl()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-else-if="secObj.actionType == 'payment'">
            <b-form-group
              label="Amount"
              invalid-feedback="Amount is required."
              ref="subamount"
            >
              <b-form-input
                v-model="secObj.amount"
                placeholder="Enter amount"
                type="number"
                @focusout="checkSubAmount()"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Category"
              invalid-feedback="Category is required."
              ref="subcat"
            >
              <v-select
                v-model="secObj.category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categories"
                :clearable="false"
                placeholder="select category"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Country"
              invalid-feedback="Country is required."
              ref="subcountry"
            >
              <v-select
                v-model="secObj.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countries"
                :clearable="false"
                placeholder="select country"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group
          label="Details"
          invalid-feedback="Details is required."
          ref="subdetails"
        >
          <b-form-textarea
            v-model.trim="secObj.details"
            placeholder="Enter details here"
          />
          <!-- @focusout="CheckSubDetails()" -->
        </b-form-group>
      </b-form>
      <br />
      <div>
        <b-button
          class="float-right ml-5"
          variant="primary"
          small
          :disabled="imgLoading == true || saving"
          @click="saveSection()"
        >
          <b-spinner
            v-if="saving"
            variant="light"
            small
            type="grow"
            label="Spinning"
          ></b-spinner>
          <span class="text-nowrap" v-else>Save</span>
        </b-button>
      </div>
    </b-modal>

    <b-card no-body v-if="showSections">
      <b-row class="p-2">
        <b-col md="12">
          <b-media vertical-align="center">
            <template #aside>
              <b-button
                @click="showSections = false"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                class="btn-icon rounded-circle mr-50"
                v-b-tooltip.hover.top
                title="Back"
              >
                <feather-icon icon="ArrowLeftIcon" size="16" />
              </b-button>
            </template>

            <h1 class="mb-0">
              {{ currentItem.title }}
            </h1>
          </b-media>
        </b-col>
        <b-col md="12" class="mt-1">
          <b-button @click="openSection(0)" variant="primary">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="text-nowrap">Add Subproject</span>
          </b-button>
        </b-col>
      </b-row>

      <b-table
        :items="sectionData"
        :fields="secfields"
        :busy="secLoading"
        show-empty
        responsive
      >
        <template #table-busy>
          <div class="text-center my-5">
            <b-spinner
              class="align-middle"
              type="grow"
              variant="primary"
              style="width: 3.5rem; height: 3.5rem;"
            ></b-spinner>
          </div>
        </template>

        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(image)="data">
          <b-img
            :src="data.value"
            style="width: 100px; height: 100px; object-fit: cover"
            rounded
            alt="image"
          />
        </template>

        <template #cell(actionType)="data">
          {{
            data.value == "page"
              ? getPage(data.item.pageID)
              : data.value == "url"
              ? data.item.url
              : data.value == "payment"
              ? `£${data.item.amount}`
              : data.value
          }}
        </template>

        <template #cell(actions)="data">
          <template>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.placement.left
              title="Edit"
              @click="openSection(data.item)"
            >
              <feather-icon size="16" icon="EditIcon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="ml-1 btn-icon"
              v-b-tooltip.hover.v-danger
              v-b-tooltip.placement.right
              title="Delete"
              size="16"
              @click="DeleteSection(data.item.id)"
            >
              <feather-icon size="16" icon="XIcon" />
            </b-button>
          </template>
        </template>
      </b-table>
    </b-card>

    <b-card v-else no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12">
            <div
              class="d-md-flex align-items-center justify-content-start"
              style="gap: 1.5rem"
            >
              <div>
                <b-button @click="OpenAddModal(0)" variant="primary">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="text-nowrap">Add Project</span>
                </b-button>
              </div>
              <b-form-input
                style="flex: 1;"
                v-model="searchQuery"
                class="d-inline-block mt-1 mt-md-0"
                placeholder="Search by title"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <div>
        <b-table
          :items="filters"
          :fields="fields"
          show-empty
          responsive
          :busy="dataLoading"
        >
          <template #table-busy>
            <div class="text-center my-5">
              <b-spinner
                class="align-middle"
                type="grow"
                variant="primary"
                style="width: 3.5rem; height: 3.5rem;"
              ></b-spinner>
            </div>
          </template>

          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(image)="data">
            <b-img
              :src="data.value"
              style="width: 100px; height: 100px; object-fit: cover"
              rounded
              alt="image"
            />
          </template>
          <template #cell(actionType)="data">
            {{
              data.value == "page"
                ? getPage(data.item.pageID)
                : data.value == "url"
                ? data.item.url
                : data.value == "payment"
                ? `£${data.item.amount}`
                : data.value
            }}
          </template>

          <template #cell(actions)="data">
            <template>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                v-b-tooltip.hover.v-primary
                v-b-tooltip.placement.left
                title="Manage Project"
                @click="openProject(data.item)"
              >
                <feather-icon size="16" icon="SettingsIcon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="ml-1 btn-icon"
                v-b-tooltip.hover.v-primary
                v-b-tooltip.placement.left
                title="Edit"
                @click="OpenAddModal(data.item)"
              >
                <feather-icon size="16" icon="EditIcon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="ml-1 btn-icon"
                v-b-tooltip.hover.v-danger
                v-b-tooltip.placement.right
                title="Delete"
                @click="Delete(data.item.id)"
              >
                <feather-icon size="16" icon="Trash2Icon" />
              </b-button>
            </template>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BSpinner,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import axios from "axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormGroup,
    BTable,
    BMedia,
    BMediaAside,
    BSpinner,
    BImg,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
    BPagination,
    vSelect,
    flatPickr,
  },

  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      searchQuery: "",
      currentDate: moment().format("YYYY-MM-DD"),
      plus: "https://cdn.cloudious.net/file-1678189226453-406060527.png",
      fileProfile: "",
      logoloading: false,
      docloading: false,
      request: false,
      imgList: [],
      errors: {
        validity: false,
        code: false,
        discountPercent: false,
        limit: false,
        validity: false,
      },
      statusOptions: ["Active", "Inactive"],
      filterData: "",
      date: {
        range: "",
      },
      index: null,
      cover: "",
      docs: [],
      partners: [],
      dataLoading: false,
      fields: [
        "#",
        { label: "image", key: "image" },
        { label: "Title", key: "title" },
        { label: "url / page", key: "actionType" },
        { label: "salesforceID", key: "saleforceID" },
        "actions",
      ],
      items: [],
      myObj: {
        id: 0,
        title: "",
        image: "",
        details: "",
        amount: "",
      },
      detailObj: {},
      actionOptions: [
        { text: "None", value: "none" },
        { text: "Page", value: "page" },
        { text: "URL", value: "url" },
        // { text: "Payment", value: "payment" },
        { text: "Subproject", value: "subproject" },
      ],
      subactionOptions: [
        { text: "None", value: "none" },
        { text: "Page", value: "page" },
        { text: "URL", value: "url" },
        // { text: "Payment", value: "payment" },
      ],
      pageOptions: [
        // { id: 1, title: "Home" },
        // { id: 2, title: "About" },
        // { id: 3, title: "Projects" },
        // { id: 4, title: "Contact" },
      ],
      categories: [
        "AJK Deaf Welfare School Project (Kotli)",
        "Education Fund",
        "Food Fund",
        "General Administration Fund",
        "Mohammad's (S.A.W) Kitchen",
        "Orphan Sponsorship",
        "Water Fund",
      ],
      countries: [
        "Bangladesh",
        "East Africa/India/Rohingya",
        "Europe",
        "Middle East/Palestine/Syria",
        "Pakistan",
        "Pakistan/Kashmir/Bangladesh",
        "Syria",
        "United Kingdom",
        "Where most needed",
        "Yemen",
      ],
      currentItem: {},
      showSections: false,
      allSections: [],
      sectionData: [],
      secfields: [
        "#",
        { label: "image", key: "image" },
        { label: "title", key: "title" },
        { label: "url / page", key: "actionType" },
        { label: "salesforceID", key: "saleforceID" },
        "actions",
      ],
      secObj: {},
      secLoading: false,
      imgLoading: false,
      saving: false,
    };
  },
  created() {
    let obj = this.$store.state.pageRights.find(
      (el) => el.route == this.$route.name
    );
    if (!obj) {
      this.$router.replace({ name: "misc-not-authorized" });
    } else {
      this.LoadData();
      this.LoadPages();
    }
  },
  computed: {
    filters: function() {
      return this.items.filter((pro) => {
        return pro.title.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  methods: {
    getPage(id) {
      let obj = this.pageOptions.find((el) => el.id == id);
      return obj ? obj.title : "";
    },
    getPageTitle(id) {
      let obj = this.pageOptions.find((el) => el.id == id);
      return obj ? obj.title.replace(/\s+/g, "-").toLowerCase() : "";
    },
    openProject(item) {
      this.currentItem = item;
      this.LoadSubs();
      this.showSections = true;
      // this.sectionData = this.allSections.filter((el) => el.projectID == this.currentItem.id);
    },
    LoadSubs() {
      this.secLoading = true;
      var config = {
        method: "get",
        url:
          "https://api.worldappeal.org.uk/api/Project/LoadSubs?id=" +
          this.currentItem.id,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.sectionData = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (this.secLoading = false));
    },
    openSection(id) {
      if (id == 0) {
        this.secObj = {
          id: 0,
          projectID: this.currentItem.id,
          title: "",
          image: "",
          details: "",
          buttonTitle: "",
          actionType: "none",
          page: "",
          pageID: 0,
          url: "",
          amount: "",
          status: "active",
          value: 1,
          category: "",
          country: "",
          donationLink: "",
          saleforceID: "",
        };
        this.sidebarTitle = "Add Subproject";
        this.imgLoading = false;
      } else {
        this.secObj = { ...id };
        this.sidebarTitle = "Edit Subproject";
        if (this.secObj.image) {
          this.imgLoading = "loaded";
        } else this.imgLoading = false;
      }
      this.$bvModal.show("modal-section");
    },
    DeleteSection(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // this.sectionData = this.sectionData.filter((el) => el.id != id);
          // this.allSections = this.allSections.filter((el) => el.id != id);
          // this.$bvToast.toast("Subproject deleted successfully!", {
          //   title: "Success!",
          //   variant: "success",
          //   toaster: "b-toaster-top-center",
          // });
          var config = {
            method: "delete",
            url: "https://api.worldappeal.org.uk/api/Subprojects/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                Swal.fire(
                  "Success!",
                  "Subproject has been deleted.",
                  "success"
                ).then((res) => {
                  this.LoadSubs();
                });
              } else {
                this.$bvToast.toast("Something went wrong!", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    selectSecImg() {
      let obj = this.$refs.subProfile.files[0];

      if (obj !== "") {
        this.imgLoading = true;
        let formData = new FormData();
        formData.append("file", obj);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            // console.log(e.data);
            let res = e.data.myresp[0].path;

            this.secObj.image = res;
            this.imgLoading = "loaded";
            this.checkSubImage();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteSecImg() {
      this.secObj.image = "";
      this.imgLoading = false;
      this.$refs.subProfile.value = null;
    },
    CheckSubTitle() {
      var elem = this.$refs["subtitle"];
      if (this.secObj.title == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSubButton() {
      var elem = this.$refs["subbutton"];
      if (this.secObj.buttonTitle == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkSubPage() {
      if (this.secObj.actionType == "page") {
        var elem = this.$refs["subpage"];
        if (this.secObj.pageID == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckSubUrl() {
      if (this.secObj.actionType == "url") {
        var elem = this.$refs["suburl"];
        if (this.secObj.url == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckSubDetails() {
      var elem = this.$refs["subdetails"];
      if (this.secObj.details == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkSubAmount() {
      if (this.secObj.actionType == "payment") {
        var elem = this.$refs["subamount"];
        if (this.secObj.amount == "" || this.secObj.amount <= 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    checkSubImage() {
      var elem = this.$refs["subimage"];
      if (this.secObj.image == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    saveSection() {
      this.checkSubImage();
      this.CheckSubTitle();
      this.CheckSubButton();
      this.checkSubPage();
      this.CheckSubUrl();
      // this.CheckSubDetails();
      this.checkSubAmount();

      if (
        this.checkSubImage() == false ||
        this.CheckSubTitle() == false ||
        this.CheckSubButton() == false ||
        this.checkSubPage() == false ||
        this.CheckSubUrl() == false ||
        // this.CheckSubDetails() == false ||
        this.checkSubAmount() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        // console.log(this.secObj);
        this.saving = true;
        if (this.secObj.amount == "" || this.secObj.amount <= 0) {
          this.secObj.amount = 0;
        } else this.secObj.amount = parseInt(this.secObj.amount);

        if (this.secObj.actionType == "page") {
          let tt = this.getPageTitle(this.secObj.pageID);
          this.secObj.donationLink = `https://worldappeal.org.uk/page/${
            tt ? tt : this.secObj.pageID
          }`;
        } else if (this.secObj.actionType == "url") {
          this.secObj.donationLink = this.secObj.url;
        } else this.secObj.donationLink = "";

        var config = {
          method: "post",
          url: "https://api.worldappeal.org.uk/api/SubProjects/save",
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
          data: this.secObj,
        };
        axios(config)
          .then((response) => {
            this.saving = false;
            if (response.data.status === "success") {
              // console.log(response.data);
              this.$bvToast.toast("Subproject saved successfully!", {
                title: "Success!",
                variant: "success",
                toaster: "b-toaster-top-center",
              });
              this.LoadSubs();
              this.$bvModal.hide("modal-section");
            } else {
              this.$bvToast.toast("Something went wrong!", {
                title: "Error!",
                variant: "danger",
                toaster: "b-toaster-top-center",
              });
            }
          })
          .catch(function(error) {
            this.saving = false;
            console.log(error);
          });
      }
    },
    checkImage() {
      var elem = this.$refs["image"];
      if (this.myObj.image == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkTitle() {
      var elem = this.$refs["title"];
      if (this.myObj.title == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkButton() {
      var elem = this.$refs["button"];
      if (this.myObj.buttonTitle == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkPage() {
      if (this.myObj.actionType == "page") {
        var elem = this.$refs["page"];
        if (this.myObj.pageID == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckUrl() {
      if (this.myObj.actionType == "url") {
        var elem = this.$refs["url"];
        if (this.myObj.url == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    checkDetails() {
      var elem = this.$refs["details"];
      if (this.myObj.details == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkAmount() {
      if (this.myObj.actionType == "payment") {
        var elem = this.$refs["amount"];
        if (this.myObj.amount == "" || this.myObj.amount <= 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckDoc() {
      var elem = this.$refs["doc"];
      if (this.myObj.doc == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    OpenAddModal(id) {
      if (id == 0) {
        this.myObj = {
          id: 0,
          title: "",
          image: "",
          details: "",
          buttonTitle: "",
          actionType: "none",
          page: "",
          pageID: 0,
          url: "",
          amount: "",
          status: "active",
          value: 1,
          category: "",
          country: "",
          donationLink: "",
          saleforceID: "",
        };
        this.logoloading = false;
        this.plus =
          "https://cdn.cloudious.net/file-1678189226453-406060527.png";
        this.$bvModal.show("modal-login");
        this.sidebarTitle = "Add Project";
      } else {
        this.myObj = { ...id };
        this.$bvModal.show("modal-login");
        this.sidebarTitle = "Edit Project";
        if (this.myObj.image) {
          this.plus = this.myObj.image;
          this.logoloading = "loaded";
        } else {
          this.logoloading = false;
          this.plus =
            "https://cdn.cloudious.net/file-1678189226453-406060527.png";
        }
      }
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var config = {
            method: "delete",
            url: "https://api.worldappeal.org.uk/api/Project/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                Swal.fire(
                  "Success!",
                  "Project has been deleted.",
                  "success"
                ).then((res) => {
                  this.LoadData();
                });
              } else {
                this.$bvToast.toast("Something went wrong!", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    selectlogo() {
      this.fileProfile = this.$refs.fileProfile.files[0];

      if (this.fileProfile !== "") {
        this.logoloading = true;
        let formData = new FormData();
        formData.append("file", this.fileProfile);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            // console.log(e.data);
            this.plus = e.data.myresp[0].path;

            this.myObj.image = this.plus;
            this.logoloading = "loaded";
            this.checkImage();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteLogo() {
      this.myObj.image = "";
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    selectDoc() {
      let doc = this.$refs.docprofile.files[0];

      if (doc !== "") {
        this.docloading = true;
        let formData = new FormData();
        formData.append("file", doc);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            // console.log(e.data);
            this.myObj.doc = e.data.myresp[0].path;
            this.docloading = false;
            this.CheckDoc();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteDoc() {
      this.myObj.doc = "";
      this.$refs.docprofile.value = null;
    },
    showDoc() {
      window.open(this.myObj.doc, "_blank");
    },
    AddFlavour() {
      this.checkImage();
      this.checkTitle();
      // this.checkDetails();
      this.checkButton();
      this.checkPage();
      this.CheckUrl();
      if (
        this.checkImage() == false ||
        this.checkTitle() == false ||
        // this.checkDetails() == false ||
        this.checkButton() == false ||
        this.checkPage() == false ||
        this.CheckUrl() == false ||
        this.checkAmount() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.request = true;
        if (this.myObj.amount == "" || this.myObj.amount <= 0) {
          this.myObj.amount = 0;
        } else this.myObj.amount = parseInt(this.myObj.amount);

        if (this.myObj.actionType == "page") {
          let tt = this.getPageTitle(this.myObj.pageID);
          this.myObj.donationLink = `https://worldappeal.org.uk/page/${
            tt ? tt : this.myObj.pageID
          }`;
        } else if (this.myObj.actionType == "url") {
          this.myObj.donationLink = this.myObj.url;
        } else this.myObj.donationLink = "";

        var config = {
          method: "post",
          url: "https://api.worldappeal.org.uk/api/Project/save",
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
          data: this.myObj,
        };
        axios(config)
          .then((response) => {
            this.request = false;
            if (response.data.status === "success") {
              // console.log(response.data);
              this.$bvToast.toast("Project saved successfully!", {
                title: "Success!",
                variant: "success",
                toaster: "b-toaster-top-center",
              });
              this.LoadData();
              this.$bvModal.hide("modal-login");
            } else {
              this.$bvToast.toast("Something went wrong!", {
                title: "Error!",
                variant: "danger",
                toaster: "b-toaster-top-center",
              });
            }
          })
          .catch(function(error) {
            this.request = false;
            console.log(error);
          });
      }
    },
    LoadData() {
      this.dataLoading = true;
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Project",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.items = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (this.dataLoading = false));
    },
    LoadPages() {
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Page",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.pageOptions = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
